import { Pipe, PipeTransform } from '@angular/core';
import { PaymentType } from '@models/masters';
import { PAYMENT_TYPES } from '@utils/masters';

@Pipe({ name: 'paymentTypeLabel', standalone: true })
export class PaymentTypeLabelPipe implements PipeTransform {
  transform(paymentType: PaymentType) {
    const paymentTypes = PAYMENT_TYPES;
    return paymentTypes[paymentType];
  }
}
