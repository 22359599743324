export const environment = {
  production: true,
  version: '1.2.18',
  emulator: false,
  env: 'pro',
  firebase: {
    apiKey: 'AIzaSyAAOamz6U3t5kHXrWKGK_1S-oLvY0qChdI',
    authDomain: 'ffpcv-app.firebaseapp.com',
    projectId: 'ffpcv-app',
    storageBucket: 'ffpcv-app.appspot.com',
    messagingSenderId: '665247591548',
    appId: '1:665247591548:web:aaaa607b113d47ac126df9',
    measurementId: 'G-DVH3LK9GCJ',
  },
  stripe: {
    publicKey:
      'pk_live_51KdJo9JTaPkxW8mYaSi5pgHAYuEOu6cJ8L9R3mRjvYrMdbEBL2jhGGK79TPXQoMMIh3DfvqVZEqmy3k2gWpprC9b000vBChn0w',
  },
};
