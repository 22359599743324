import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { UserService } from '@services/user.service';

@Component({
  selector: 'admin-layout',
  standalone: true,
  imports: [CommonModule, RouterModule, ToastModule],
  providers: [MessageService],
  templateUrl: './layout.html',
  styles: [
    `
      nav {
        background: #2ea3f2;
      }
    `,
  ],
})
export class Layout {
  isAdmin$ = this.usersService.isAdmin$;
  isManager$ = this.usersService.isManager$;
  isRefereeCoordinator$ = this.usersService.isRefereeCoordinator$;
  isNavVisible = false;
  constructor(
    private router: Router,
    private authService: AuthService,
    private usersService: UserService,
  ) {}

  closeNavOnMobile() {
    if (window.innerWidth < 768) {
      this.isNavVisible = false;
    }
  }

  logout() {
    this.authService.logout().then(() => {
      this.router.navigate(['/login']);
    });
  }
}
