import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const START_YEAR = 2024;

@Injectable({
  providedIn: 'root',
})
export class SeasonManagerService {
  private seasonSubject = new BehaviorSubject<string>(this.getActualSeason());
  public season$ = this.seasonSubject.asObservable();

  public setSeason(season: string): void {
    this.seasonSubject.next(season);
  }

  public getSeason(): string {
    return this.seasonSubject.getValue();
  }

  public availableSeasons(): string[] {
    const actualYear = new Date().getFullYear();
    const seasons = [];
    for (let year = START_YEAR; year <= actualYear + 1; year++) {
      seasons.push(`${year - 1}-${year}`);
    }
    return seasons;
  }

  public getActualSeason(): string {
    const date = new Date();
    const month = date.getMonth();
    const year = date.getFullYear();
    if (month < 6) {
      return `${year - 1}-${year}`;
    }
    return `${year}-${year + 1}`;
  }
}
