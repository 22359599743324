import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { PasswordModule } from 'primeng/password';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'admin-recovery',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    PasswordModule,
    MessageModule,
    FormsModule,
    RouterModule,
  ],
  templateUrl: './recovery.html',
  styles: [
    `
      main {
        background: linear-gradient(241.11deg, #00ffd8 0%, #0029e2 100%);
      }
    `,
  ],
})
export class Recovery {
  public showError = false;
  public success = false;
  public recoveryForm = {
    email: '',
  };

  constructor(private authService: AuthService) {}

  recovery() {
    this.authService
      .resetPassword(this.recoveryForm.email)
      .then(() => {
        this.recoveryForm.email = '';
        this.success = true;
        this.showError = false;
      })
      .catch(() => {
        this.recoveryForm.email = '';
        this.showError = true;
      });
  }
}
