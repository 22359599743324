import { Pipe, PipeTransform } from '@angular/core';
import { PaymentMethod } from '@models/masters';
import { PAYMENT_METHODS } from '@utils/masters';

@Pipe({ name: 'paymentMethodLabel', standalone: true })
export class PaymentMethodLabelPipe implements PipeTransform {
  transform(paymentMethod: PaymentMethod) {
    const paymentMethods = PAYMENT_METHODS;
    return paymentMethods[paymentMethod];
  }
}
