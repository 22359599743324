import { Injectable } from '@angular/core';
import {
  Auth,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from '@angular/fire/auth';
import { BehaviorSubject, Observable, OperatorFunction, Subject, filter, map } from 'rxjs';

export interface AuthStatus {
  // Undefined while checking
  loggedIn: boolean | undefined;
  uid?: string;
}

const EMPTY_LOGGED = { loggedIn: undefined };

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authStatus = new BehaviorSubject<AuthStatus>(EMPTY_LOGGED);
  private authAnonymously = new BehaviorSubject<AuthStatus>(EMPTY_LOGGED);

  /**
   * Emits when user logs out.
   */
  public onLogout = new Subject<void>();

  public isLoggedIn$: Observable<boolean> = this.authStatus.pipe(
    filter((status) => status.loggedIn != undefined),
    map((v) => Boolean(v.loggedIn)),
  );
  
  public isLoggedInAnonymously$: Observable<boolean> = this.authAnonymously.pipe(
    filter((status) => status.loggedIn != undefined),
    map((v) => Boolean(v.loggedIn)),
  );

  /**
   * Returns loggedIn user id. After logout it does not change until next login.
   */
  public uid$: Observable<string> = this.authStatus.pipe(
    filter((status) => status.loggedIn === true && status.uid !== undefined) as OperatorFunction<
      AuthStatus,
      Required<AuthStatus>
    >,
    map((v) => v.uid),
  );

  constructor(private auth: Auth) {
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        if (user.email) {
          this.authStatus.next({ loggedIn: true, uid: user.uid });
        } else if (user.isAnonymous) {
          this.authAnonymously.next({ loggedIn: true });
        }
      } else {
        this.authStatus.next({ loggedIn: false });
      }
    });
  }

  login(email: string, pass: string) {
    this.authStatus.next(EMPTY_LOGGED);
    return signInWithEmailAndPassword(this.auth, email, pass);
  }

  signInAnonymously() {
    this.authStatus.next(EMPTY_LOGGED);
    return signInAnonymously(this.auth);
  }

  logout() {
    this.onLogout.next();
    return signOut(this.auth).then(() => this.authStatus.next({ loggedIn: false }));
  }

  register(email: string, pass: string) {
    this.authStatus.next(EMPTY_LOGGED);
    return createUserWithEmailAndPassword(this.auth, email, pass);
  }

  resetPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email);
  }

  confirmPasswordReset(oobCode: string, newPassword: string): Promise<void> {
    return confirmPasswordReset(this.auth, oobCode, newPassword);
  }

  updatePassword(newPassword: string) {
    const currentUser = this.auth.currentUser;
    if (currentUser) {
      return updatePassword(currentUser, newPassword);
    } else {
      return Promise.reject('Current user is null. Unable to update password.');
    }
  }
}
