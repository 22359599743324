import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from './environments/environment';
import { enableProdMode, isDevMode } from '@angular/core';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: 'https://3ad26b4e5df5b0b13fd8c144012c15b2@o1168083.ingest.us.sentry.io/4507424584302592',
  enabled: !isDevMode(),
  environment: environment.env,
  release: 'admin@' + environment.version,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost'],
});

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
