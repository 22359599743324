import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore.service';
import { shareReplay, Observable, map } from 'rxjs';
import { COLLECTIONS } from '@models/collections';
import { Id } from '@models/common';

@Injectable({
  providedIn: 'root',
})
export class SettingsMasterService {
  settings$ = this.firestore
    .getDocument<any>(COLLECTIONS.MASTERS, COLLECTIONS.MASTERS_SETTINGS_DOC)
    .pipe(shareReplay(1));

  constructor(private firestore: FirestoreService) {}

  getSetting<T = any>(id: Id): Observable<T> {
    return this.settings$.pipe(map((settings) => settings[id]));
  }
}
