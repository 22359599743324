export const prime = {
  accept: 'Sí',
  addRule: 'Agregar regla',
  am: 'AM',
  apply: 'Aplicar',
  cancel: 'Cancelar',
  choose: 'Escoger',
  chooseDate: 'Elige fecha',
  chooseMonth: 'Elige el mes',
  chooseYear: 'Elige Año',
  clear: 'Limpiar',
  completed: 'Terminado',
  contains: 'Contenga',
  custom: 'Personalizar',
  dateAfter: 'Fecha después de',
  dateBefore: 'Fecha antes de',
  dateFormat: 'dd/mm/yy',
  dateIs: 'Fecha igual a',
  dateIsNot: 'Fecha diferente a',
  dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
  dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
  dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
  emptyFilterMessage: 'Sin opciones disponibles',
  emptyMessage: 'No se han encontrado resultados',
  emptySearchMessage: 'Sin opciones disponibles',
  emptySelectionMessage: 'Ningún artículo seleccionado',
  endsWith: 'Termine con',
  equals: 'Igual a',
  fileSizeTypes: ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
  filter: 'Filtrar',
  firstDayOfWeek: 1,
  gt: 'Mayor que',
  gte: 'Mayor o igual a',
  lt: 'Menor que',
  lte: 'Menor o igual a',
  matchAll: 'Coincidir todo',
  matchAny: 'Coincidir con cualquiera',
  medium: 'Medio',
  monthNames: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  nextDecade: 'Próxima década',
  nextHour: 'próxima hora',
  nextMinute: 'Siguiente minuto',
  nextMonth: 'Próximo mes',
  nextSecond: 'Siguiente segundo',
  nextYear: 'El próximo año',
  noFilter: 'Sin filtro',
  notContains: 'No contenga',
  notEquals: 'Diferente a',
  now: 'Ahora',
  passwordPrompt: 'Escriba una contraseña',
  pending: 'Pendiente',
  pm: 'PM',
  prevDecade: 'Década anterior',
  prevHour: 'Hora anterior',
  prevMinute: 'Minuto anterior',
  prevMonth: 'Mes anterior',
  prevSecond: 'Anterior Segundo',
  prevYear: 'Año anterior',
  reject: 'No',
  removeRule: 'Eliminar regla',
  searchMessage: '{0} resultados están disponibles',
  selectionMessage: '{0} elementos seleccionados',
  showMonthAfterYear: false,
  startsWith: 'Comience con',
  strong: 'Fuerte',
  today: 'Hoy',
  upload: 'Subir',
  weak: 'Débil',
  weekHeader: 'Sem',
  aria: {
    cancelEdit: 'Cancelar editado',
    close: 'Cerrar',
    collapseLabel: 'Colapsar',
    collapseRow: 'Reducir Fila',
    editRow: 'Editar fila',
    expandLabel: 'Expandir',
    expandRow: 'Expandir Fila',
    falseLabel: 'Falso',
    filterConstraint: 'Restricción de filtro',
    filterOperator: 'Operador de filtro',
    firstPageLabel: 'Primera Página',
    gridView: 'Vista de cuadrícula',
    hideFilterMenu: 'Ocultar menú del filtro',
    jumpToPageDropdownLabel: 'Ir al menú desplegable de página',
    jumpToPageInputLabel: 'Ir a la entrada de página',
    lastPageLabel: 'Última Página',
    listView: 'Vista de lista',
    moveAllToSource: 'Mover todo al origen',
    moveAllToTarget: 'Mover todo al objetivo',
    moveBottom: 'Desplazarse hacia abajo',
    moveDown: 'Bajar',
    moveTop: 'Mover arriba',
    moveToSource: 'Mover al origen',
    moveToTarget: 'Mover al objetivo',
    moveUp: 'Subir',
    navigation: 'Navegación',
    next: 'Siguiente',
    nextPageLabel: 'Siguiente Página',
    nullLabel: 'No seleccionado',
    otpLabel: 'Introduzca el carácter de contraseña de un solo uso {0}',
    pageLabel: 'Página {page}',
    passwordHide: 'Contraseña oculta',
    passwordShow: 'Mostrar contraseña',
    previous: 'Anterior',
    previousPageLabel: 'Página Anterior',
    rotateLeft: 'Girar a la izquierda',
    rotateRight: 'Girar a la derecha',
    rowsPerPageLabel: 'Filas por página',
    saveEdit: 'Guardar editado',
    scrollTop: 'Desplazarse hacia arriba',
    selectAll: 'Seleccionar todos',
    selectLabel: 'Seleccionar',
    selectRow: 'Seleccionar fila',
    showFilterMenu: 'Mostrar menú del filtro',
    slide: 'Deslizar',
    slideNumber: '{slideNumber}',
    star: '1 estrella',
    stars: '{star} estrellas',
    trueLabel: 'Verdadero',
    unselectAll: 'Deseleccionar todos',
    unselectLabel: 'Deseleccionar',
    unselectRow: 'Desmarcar fila',
    zoomImage: 'Ampliar imagen',
    zoomIn: 'Ampliar',
    zoomOut: 'Reducir',
  },
};
