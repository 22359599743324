<main class="flex flex-col gap-4 items-center">
  <div class="flex justify-between items-center">
    <h4 class="ml-10 md:ml-0 !text-lg">
      Bienvenido {{ (user$ | async)?.name }} {{ (user$ | async)?.firstLastName }}
      {{ (user$ | async)?.secondLastName }}
    </h4>
  </div>
  <div class="text-center">
    <h4 class="ml-10 md:ml-0 !text-lg">Mantenimiento activado</h4>
    <p class="text-center">
      El sistema se encuentra en mantenimiento, por favor intenta más tarde.
    </p>
  </div>
</main>
