import { Address, Entity } from './common';
import { Season } from './competitions';
import { Match } from './match';
import { PlayerRanking } from './ranking';

export interface User extends Entity {
  name: string;
  firstLastName: string;
  secondLastName: string;
  email: string;
  documentType: string | 'dni' | 'nie' | 'nia';
  document: string;
  documentImage?: string;
  phone: string;
  birthDate: Date;
  address: Address;
  gender: UserGender;
  profile?: ProfileType;
  position?: PositionType;
  racketBrand?: Racket;
  racketModel?: string;
  photo?: string;
  fcmToken?: string;
  //
  types?: UserType[]; // Should be removed?
  clubId?: string;
  licenseNumber?: string;
  stats?: MatchStats;
  isAdmin?: boolean;
  //
  history?: {
    teams?: {
      [scope: string]: {
        fromTeamId: string;
        toTeamId: string;
      };
    };
  };
}

export type MatchStats = {
  [scope: string]: {
    [level: number]: number;
  };
};

export enum UserGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum UserType {
  ADMIN = 'ADMIN',
  REFEREE_COORDINATOR = 'REFEREE_COORDINATOR',
}

export enum ProfileType {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
}

export enum Racket {
  ARTENGO = 'ARTENGO',
  BABOLAT = 'BABOLAT',
  DUNLOP = 'DUNLOP',
  HEAD = 'HEAD',
  MASTERPRO = 'MASTERPRO',
  PRINCE = 'PRINCE',
  PROKENNEX = 'PROKENNEX',
  TECNIFIBRE = 'TECNIFIBRE',
  URBALL = 'URBALL',
  VOLKL = 'VOLKL',
  WILSON = 'WILSON',
  YONEX = 'YONEX',
  OTHER = 'OTHER',
}

export enum PositionType {
  DELANTERO = 'DELANTERO',
  ZAGUERO = 'ZAGUERO',
  BOTH = 'BOTH',
}

export interface UserRegistrationFindRequest {
  document: string;
}

export interface PublicInfoUser {
  name: string;
  firstLastName: string;
  secondLastName: string;
  photo?: string;
  clubId?: string;
  matches: {
    match: Match;
    season: Season;
  }[];
  ranking: {
    season: Season;
    myRanking: PlayerRanking;
  }[];
}

export interface UserUnsubscribe {
  userId: string;
}


export interface UserSuggested extends Entity {
  document: string;
  name: string;
  firstLastName: string;
  secondLastName: string;
  clubId: string;
  photo: string;
}