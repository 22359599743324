import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { PasswordModule } from 'primeng/password';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'admin-new-password',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    PasswordModule,
    MessageModule,
    FormsModule,
    RouterModule,
  ],
  templateUrl: './new-password.html',
  styles: [
    `
      main {
        background: linear-gradient(241.11deg, #00ffd8 0%, #0029e2 100%);
      }
    `,
  ],
})
export class NewPassword {
  public showError = false;
  public success = false;

  public passwordForm = {
    newPassword: '',
  };

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
  ) {}

  recovery() {
    const oobCode = this.route.snapshot.queryParams['oobCode'];
    this.authService
      .confirmPasswordReset(oobCode, this.passwordForm.newPassword)
      .then(() => {
        this.success = true;
        this.showError = false;
        this.passwordForm.newPassword = '';
      })
      .catch(() => {
        this.showError = true;
      });
  }
}
