import { Component, isDevMode, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { prime } from './primeng.i18n';
import { CommonModule } from '@angular/common';
import { SettingsMasterService } from '@services/masters/settings-master.service';

@Component({
  selector: 'admin-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule],
  template: `<router-outlet></router-outlet>
    <div class="dev" *ngIf="isDev">DEV BUILD</div>`,
  styles: [
    `
      .dev {
        background-color: red;
        color: white;
        font-size: 12px;
        position: fixed;
        padding: 2px;
        right: -8vw;
        text-align: center;
        transform: rotate(45deg);
        width: 19vw;
        top: 1vw;
        pointer-events: none;
        z-index: 2;
      }
    `,
  ],
})
export class AppComponent implements OnInit {
  public isDev = isDevMode();
  constructor(
    private config: PrimeNGConfig,
    private settings: SettingsMasterService,
    private router: Router,
  ) {
    this.config.setTranslation(prime);
  }

  ngOnInit() {
    this.checkMaintenance();
  }

  checkMaintenance() {
    this.settings.getSetting('maintenanceAdmin').subscribe((maintenance) => {
      const currentUrl = this.router.url;
      if (maintenance) {
        this.router.navigate(['maintenance']);
      } else {
        if (currentUrl === '/maintenance') {
          this.router.navigate(['dashboard']);
        }
      }
    });
  }
}
