import { Entity } from './common';
import { Category } from './masters';
import { User, UserGender } from './user';

export interface LicenseRate extends Entity {
  categories: Category[];
  genders: UserGender[];
  price: number;
  licenseType: LicenseType;
  hasSupplements: boolean;
  supplements?: Supplement[];
}

export interface Supplement {
  for: LicenseType;
  price: number;
}

export enum LicenseType {
  PLAYER = 'PLAYER',
  REFEREE = 'REFEREE',
  DELEGATE = 'DELEGATE',
  INDEPENDENT = 'INDEPENDENT',
}

export interface LicensePlayerRate {
  category: Category;
  gender: UserGender;
  price: number;
  licenseType: LicenseType;
}
export interface LicenseUserRate extends Entity {
  userId: string;
  category: Category;
  clubId: string;
  gender: UserGender;
  price: number;
  licenseType: LicenseType;
  seasonYear: string; //Year
}

export interface LicenseRateRequest {
  licenseType: LicenseType;
}

export interface LicenseRateSeason extends Entity {
  teamRates?: Rate;
  licenseRates?: LicenseRate[];
}

export interface Rate {
  standard: number;
  school: number;
  freeTeamsFrom: number;
}

export interface LicensePayment {
  licenseType: LicenseType;
  birthDate?: Date;
  gender?: UserGender;
  price: number;
  userId?: string;
  user?: User;
  token?: string;
}
