<main class="w-screen h-screen bg-no-repeat bg-cover flex justify-center items-center px-4 md:px-0">
  <form
    class="bg-white shadow-5 rounded-lg w-[540px] p-shadow-5 flex flex-col items-center gap-6 px-4 md:px-20 py-4"
    (ngSubmit)="login()"
  >
    <img class="w-[216px]" src="/assets/images/logo.png" />
    <h4>Iniciar sesión</h4>

    <p-iconField iconPosition="left" class="w-full">
      <p-inputIcon styleClass="pi pi-user" />
      <input
        type="text"
        [(ngModel)]="loginForm.email"
        name="email"
        pInputText
        autocomplete="email"
        class="w-full"
        placeholder="Usuario"
      />
    </p-iconField>

    <p-iconField iconPosition="left" class="w-full">
      <p-inputIcon styleClass="pi pi-lock custom-icon" />
      <input
        type="password"
        [(ngModel)]="loginForm.password"
        name="password"
        autocomplete="current-password"
        class="w-full"
        pInputText
        placeholder="Contraseña"
      />
    </p-iconField>

    <p-message
      *ngIf="showError"
      severity="danger"
      text="Usuario o contraseña incorrecta"
      class="text-red-500"
    ></p-message>

    <p-message
      *ngIf="roleError"
      severity="danger"
      text="No tienes permisos para acceder a esta aplicación"
      class="text-red-500"
    ></p-message>

    <p-button type="submit" [rounded]="true" icon="pi pi-arrow-circle-right" label="Acceder"></p-button>
    <p-button [link]="true" [routerLink]="['/recovery']">He olvidado mi contraseña</p-button>
  </form>
</main>
