import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { ButtonModule } from 'primeng/button';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { MessageModule } from 'primeng/message';
import { CommonModule } from '@angular/common';
import { UserService } from '@services/user.service';
import { UserType } from '@models/user';
import { first, of, switchMap } from 'rxjs';

const SUPPORTED_ROLES = [UserType.ADMIN];

@Component({
  selector: 'admin-login',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    PasswordModule,
    MessageModule,
    FormsModule,
    RouterModule,
  ],
  templateUrl: './login.html',
  styleUrl: './login.css',
})
export class Login {
  public showError = false;
  public roleError = false;
  public loginForm = {
    email: '',
    password: '',
  };

  constructor(
    private authService: AuthService,
    private usersService: UserService,
    private router: Router,
  ) {}

  login() {
    this.authService
      .login(this.loginForm.email, this.loginForm.password)
      .then(() => {
        this.usersService.roles$
          .pipe(
            switchMap((roles) => {
              if (roles.includes(UserType.ADMIN) || roles.includes(UserType.REFEREE_COORDINATOR)) {
                return of(true);
              } else {
                return this.usersService.isManager$;
              }
            }),
            first(),
          )
          .subscribe((hasAccess) => {
            if (hasAccess) {
              this.router.navigate(['/dashboard'], { replaceUrl: true });
            } else {
              this.authService.logout().then(() => {
                this.showError = false;
                this.roleError = true;
              });
            }
          });
      })
      .catch(() => {
        this.showError = true;
        this.roleError = false;
      });
  }
}
