import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'admin-maintenance',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TooltipModule,
    TableModule,
  ],
  templateUrl: './maintenance.html',
})
export class Maintenance {
  public user$ = this.usersService.user$;

  constructor(
    private usersService: UserService,
    public router: Router,
  ) {}

}
