import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { UserType } from '@models/user';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';
import { from, of, Observable } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';

export function authGuard(role: UserType[], allowManager: boolean = false, logout: boolean = false): CanActivateFn {
  return (): Observable<boolean | UrlTree> => {
    const authService = inject(AuthService);
    const userService = inject(UserService);
    const router = inject(Router);

    return authService.isLoggedIn$.pipe(
      catchError(() => of(false)),
      first(),
      switchMap((isLoggedIn) => {
        if (!isLoggedIn) {
          return of(router.createUrlTree(['/login']));
        } else {
          return userService.roles$.pipe(
            first(),
            switchMap((roles) => {
              const hasRole = roles.some((r) => role.includes(r));
              if (hasRole) {
                return of(true);
              } else if (allowManager) {
                return userService.isManager$.pipe(
                  first(),
                  switchMap((isManager) => {
                    if (isManager) {
                      return of(true);
                    } else {
                      return of(router.createUrlTree(['/dashboard']));
                    }
                  }),
                );
              } else {
                return from(authService.logout()).pipe(map(() => router.createUrlTree(['/login'])));
              }
            }),
          );
        }
      }),
    );
  };
}
