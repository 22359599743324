import { Pipe, PipeTransform } from '@angular/core';
import { MovementType } from '@models/masters';
import { MOVEMENT_TYPES } from '@utils/masters';

@Pipe({ name: 'movementTypeLabel', standalone: true })
export class MovementTypeLabelPipe implements PipeTransform {
  transform(type: MovementType) {
    const types = MOVEMENT_TYPES;
    return types[type];
  }
}
