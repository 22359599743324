export enum Scope {
  MIXTAS = 'MIXTAS',
  CAFF = 'CAFF',
  JE = 'JE',
  PELOTA_LENTA = 'PELOTA_LENTA',
  AFICIONADO = 'AFICIONADO'
}

export enum Modality {
  PREOLIMPICA = 'PREOLIMPICA',
  OLIMPICA = 'OLIMPICA',
  PALETA_GOMA = 'PALETA-GOMA',
  FRONT_BALL = 'FRONT-BALL',
  PREOLIMPICA_INDIVIDUAL = 'PREOLIMPICA-INDIVIDUAL',
  OLIMPICA_INDIVIDUAL = 'OLIMPICA-INDIVIDUAL',
  PALETA_GOMA_INDIVIDUAL = 'PALETA-GOMA-INDIVIDUAL',
}

export enum Category {
  BENJAMIN = 'BENJAMIN',
  ALEVIN = 'ALEVIN',
  INFANTIL = 'INFANTIL',
  CADETE = 'CADETE',
  JUVENIL = 'JUVENIL',
  SENIOR = 'SENIOR',
  MASTER = 'MASTER',
  MASTER50 = 'MASTER50',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  MIXED = 'MIXED',
}

export enum CompetitionType {
  LEAGUE = 'LEAGUE',
  CUP = 'CUP',
  JE = 'JE',
}

export enum WeekDay {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

export enum PaymentMethod {
  CARD = 'CARD',
  BANK = 'BANK',
}

export enum PaymentType {
  TEAMS = 'TEAMS',
  PLAYERS = 'PLAYERS',
}

export enum MovementType {
  INCOME = 'INCOME',
  EXPENDITURE = 'EXPENDITURE',
}

export enum ZoneType {
  AUTONOMICO = 'AUTONOMICO',
  ALICANTE = 'ALICANTE',
  VALENCIA = 'VALENCIA',
  CASTELLON = 'CASTELLON',
  JECV = 'JECV',
}