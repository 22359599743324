<main class="w-screen h-screen flex justify-center items-center">
  <form
    class="bg-white shadow-5 rounded-lg w-[540px] p-shadow-5 flex flex-col items-center gap-6 px-20 py-4"
    (ngSubmit)="recovery()"
  >
    <img class="w-[216px]" src="/assets/images/logo.png" />
    <h4>Nueva contraseña</h4>

    <p-iconField iconPosition="left" class="w-full">
      <p-inputIcon styleClass="pi pi-lock custom-icon" />
      <input
        type="password"
        [(ngModel)]="passwordForm.newPassword"
        name="newPassword"
        pInputText
        autocomplete="new-password"
        class="w-full"
        placeholder="Nueva contraseña"
      />
    </p-iconField>

    <p-message *ngIf="showError" severity="danger" text="No se podido actualizar la contraseña"></p-message>
    <p-message *ngIf="success" severity="success" text="Contraseña actualizada correctamente"></p-message>

    <p-button type="submit" [rounded]="true" label="Continuar"></p-button>
    <p-button [link]="true" [routerLink]="['/login']">Iniciar sesión</p-button>
  </form>
</main>
