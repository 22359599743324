<main
  class="w-screen h-screen flex justify-center items-center"
>
  <form
    class="bg-white shadow-5 rounded-lg w-[540px] p-shadow-5 flex flex-col items-center gap-6 px-20 py-4"
    (ngSubmit)="recovery()"
  >
    <img class="w-[216px]" src="/assets/images/logo.png" />
    <h4>Recuperar contraseña</h4>

    <p-iconField iconPosition="left" class="w-full">
      <p-inputIcon styleClass="pi pi-user" />
      <input
        type="text"
        [(ngModel)]="recoveryForm.email"
        name="email"
        pInputText
        autocomplete="email"
        class="w-full"
        placeholder="Correo electrónico"
      />
    </p-iconField>

    <p-message *ngIf="showError" severity="danger" text="No se ha podido recuperar la contraseña"></p-message>
    <p-message
      *ngIf="success"
      severity="success"
      text="Revise su correo electrónico para seguir con la recuperación"
    ></p-message>

    <p-button type="submit" [rounded]="true" label="Continuar"></p-button>
    <p-button [link]="true" [routerLink]="['/login']">Iniciar sesión</p-button>
  </form>
</main>
