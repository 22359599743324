import { Injectable } from '@angular/core';
import { and, where } from '@angular/fire/firestore';
import { COLLECTIONS } from '@models/collections';
import { Movement } from '@models/inscription';
import { LicenseUserRate } from '@models/license';
import { PaymentType } from '@models/masters';
import { Match } from '@models/match';
import { Team } from '@models/teams';
import { combineLatest, map, shareReplay, switchMap } from 'rxjs';
import { FirestoreService } from './firestore.service';
import { SeasonManagerService } from './season-manager.service';
import { SeasonsService } from './seasons.service';

@Injectable({
  providedIn: 'root',
})
export class StatsService {
  private readonly expenses$ = this.firestore
    .listDocuments<Movement>(COLLECTIONS.MOVEMENTS, where('movementType', '==', 'EXPENDITURE'))
    .pipe(shareReplay(1));

  private readonly incomes$ = this.firestore
    .listDocuments<Movement>(COLLECTIONS.MOVEMENTS, where('movementType', '==', 'INCOME'))
    .pipe(shareReplay(1));

  private readonly teams$ = this.seasonManager.season$.pipe(
    switchMap((season) => this.firestore.listDocuments<Team>(COLLECTIONS.TEAMS, where('seasonYear', '==', season))),
    shareReplay(1),
  );

  private readonly licenses$ = this.seasonManager.season$.pipe(
    switchMap((seasonYear) =>
      this.firestore.listDocumentsGroup<LicenseUserRate>(
        COLLECTIONS.USERS_LICENSES,
        where('seasonYear', '==', seasonYear),
      ),
    ),
    shareReplay(1),
  );

  public adminStats$ = combineLatest([this.expenses$, this.incomes$, this.teams$, this.licenses$]).pipe(
    map(([expenses, incomes, teams, licenses]) => {
      const totalExpenses = expenses.reduce((acc, curr) => acc + +curr.amount, 0);
      const totalIncomes = incomes.reduce((acc, curr) => acc + +curr.amount, 0);
      const incomesByType = incomes.reduce(
        (acc, income) => {
          const type = income.paymentType as PaymentType;
          if (type && typeof type === 'string') {
            if (!acc[type]) {
              acc[type] = 0;
            }
            acc[type] += +income.amount;
          }
          return acc;
        },
        {} as Record<PaymentType, number>,
      );

      const licensesByUserAndType = licenses.reduce((acc, license) => {
        const userId = license.userId;
        const licenseType = license.licenseType;

        if (!userId || !licenseType || typeof userId !== 'string' || typeof licenseType !== 'string') {
          return acc;
        }

        if (!acc.has(userId)) {
          acc.set(userId, new Set<string>());
        }

        const userLicenses = acc.get(userId);
        if (userLicenses) {
          userLicenses.add(licenseType);
        }

        return acc;
      }, new Map<string, Set<string>>());

      const licensesByType = Array.from(licensesByUserAndType.values()).reduce(
        (acc, licenseTypesSet) => {
          licenseTypesSet.forEach((type) => {
            if (!acc[type]) {
              acc[type] = 0;
            }
            acc[type]++;
          });
          return acc;
        },
        {} as Record<string, number>,
      );

      const uniqueUsersWithLicenses = licensesByUserAndType.size;

      return {
        totalExpenses,
        totalIncomes,
        netBalance: totalExpenses - totalIncomes,
        teamsCount: teams.length,
        uniqueUsersWithLicenses,
        licensesByType,
        incomesByType,
      };
    }),
  );

  public allMatches$ = this.seasonsService.all$.pipe(
    switchMap((seasons) => {
      const seasonIds = seasons.map((season) => season.id);
      const chunkArray = (array: string[], size: number) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
          result.push(array.slice(i, size + i));
        }
        return result;
      };

      const seasonsChunks = chunkArray(seasonIds, 30);
      return combineLatest(
        seasonsChunks.map((seasonChunk) =>
          this.firestore
            .listDocuments<Match>(COLLECTIONS.MATCH, and(where('seasonId', 'in', seasonChunk)))
            .pipe(map((matches) => matches)),
        ),
      ).pipe(map((results) => results.flat()));
    }),
    shareReplay(1),
  );

  public matchesSummary$ = combineLatest([this.allMatches$, this.seasonsService.all$, this.teams$]).pipe(
    map(([matches, seasons, teams]) => {
      const currentDate = new Date();

      const ongoingMatches = matches.filter((match) => match.status === 'IN-PROGRESS').length;

      // const postponedMatches = matches.filter(
      //   (match) => (match.officialDate ?? 0) < currentDate && (match.date ?? 0) > currentDate && match.status === 'PENDING',
      // ).length;

      const finishedMatches = matches.filter((match) => match.status === 'FINISHED').length;
      const pendingMatches = matches.filter((match) => match.status === 'PENDING').length;

      const matchesWithIncidents = matches.filter((match) => match.incident && match.incident !== null).length;

      const uniqueClubIds = new Set(teams.map((team) => team.clubId));
      const numOfClubs = uniqueClubIds.size;
      return {
        ongoingMatches,
        // postponedMatches,
        finishedMatches,
        matchesWithIncidents,
        pendingMatches,
        numOfCompetitions: seasons.length,
        numOfClubs,
      };
    }),
  );

  constructor(
    private firestore: FirestoreService,
    private seasonManager: SeasonManagerService,
    private seasonsService: SeasonsService,
  ) {}
}
