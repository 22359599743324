import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MovementTypeLabelPipe } from '@pipes/movement-type-label.pipe';
import { PaymentMethodLabelPipe } from '@pipes/payment-method-label.pipe';
import { PaymentTypeLabelPipe } from '@pipes/payment-type-label.pipe';
import { ClubManagerService } from '@services/club-manager.service';
import { UserService } from '@services/user.service';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { map } from 'rxjs';
import { AdminHomeComponent } from './admin-home/admin-home.component';

@Component({
  selector: 'admin-home',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TooltipModule,
    TableModule,
    PaymentMethodLabelPipe,
    PaymentTypeLabelPipe,
    MovementTypeLabelPipe,
    AdminHomeComponent
  ],
  templateUrl: './home.html',
  styleUrl: './home.css',
})
export class Home {
  public user$ = this.usersService.user$;
  public isClubManager$ = this.usersService.isManager$;
  public isAdmin$ = this.usersService.isAdmin$;

  public myClub$ = this.clubManagerService.club$;
  public clubBalance$ = this.clubManagerService.clubBalance$;
  public myTeams$ = this.clubManagerService.myTeams$.pipe(map((teams) => teams.length));
  public myPlayers$ = this.clubManagerService.myPlayers$.pipe(map((players) => players.length));
  isAdminDashboardSelected = false;

  constructor(
    private usersService: UserService,
    private clubManagerService: ClubManagerService,
    public router: Router,
    private clipBoard: Clipboard,
    private messageService: MessageService,
  ) {}

  copy() {
    this.clipBoard.copy('ES70 2100 2736 4102 0011 9530');
    this.messageService.add({
      severity: 'success',
      summary: 'Número de cuenta copiado',
      detail: 'El número de cuenta ha sido copiado correctamente',
    });
  }
}
