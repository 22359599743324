import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { Club } from '@models/clubs';
import { COLLECTIONS } from '@models/collections';
import { Optional } from '@models/utils';
import { firstValueFrom, shareReplay } from 'rxjs';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root',
})
export class ClubsService {
  private COLLECTION = COLLECTIONS.CLUBS;

  public all$ = this.firestore.listDocuments<Club>(this.COLLECTION).pipe(shareReplay(1));

  constructor(private firestore: FirestoreService) {}

  get(id: string) {
    return this.firestore.getDocument<Club>(this.COLLECTION, id);
  }

  create(user: Omit<Club, 'id'>) {
    return this.firestore.create(this.COLLECTION, user);
  }

  update(id: string, user: Partial<Club>) {
    return this.firestore
      .update(this.COLLECTION, {
        id,
        ...user,
      })
      .then(() => id);
  }

  createOrUpdate(user: Optional<Club, 'id'>) {
    return user.id ? this.update(user.id, user) : this.create(user);
  }

  getReport() {
    return this.firestore.listDocuments<Club>(this.COLLECTION);
  }

  checkIfDuplicatedCif(cif: string) {
    return firstValueFrom(this.firestore.findDocument<Club>(this.COLLECTION, where('cif', '==', cif)));
  }
}
